var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.blacklisted
    ? _c(
        "div",
        { staticClass: "blacklist-check-wrapper" },
        [
          _vm.message
            ? _c("p", { staticClass: "summary-msg" }, [
                _vm._v(_vm._s(_vm.message)),
              ])
            : _vm._e(),
          _vm.showAll || (_vm.blacklisted && _vm.blacklistFiltered.length > 0)
            ? _vm._l(_vm.blacklistFiltered, function (blacklist) {
                return _c(
                  "div",
                  { key: blacklist.id, staticClass: "blacklist-row" },
                  [
                    blacklist.detected
                      ? _c("span", { staticClass: "status detected" }, [
                          _vm._v("✘"),
                        ])
                      : _c("span", { staticClass: "status not-detected" }, [
                          _vm._v("✔"),
                        ]),
                    _c("span", [_vm._v(_vm._s(blacklist.name))]),
                  ]
                )
              })
            : _c("div", { staticClass: "all-clear" }, [
                _c("p", [_vm._v("No Detections Found")]),
                _c("span", { staticClass: "tick" }, [_vm._v("✔")]),
              ]),
          _c(
            "p",
            {
              staticClass: "toggle-view-all",
              on: {
                click: function ($event) {
                  _vm.showAll = !_vm.showAll
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.showAll
                      ? _vm.$t("widgets.general.show-less")
                      : _vm.$t("widgets.general.show-more")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }